// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-algorithms-index-js": () => import("./../src/pages/blog/algorithms/index.js" /* webpackChunkName: "component---src-pages-blog-algorithms-index-js" */),
  "component---src-pages-blog-algorithms-java-index-js": () => import("./../src/pages/blog/algorithms/java/index.js" /* webpackChunkName: "component---src-pages-blog-algorithms-java-index-js" */),
  "component---src-pages-blog-algorithms-java-sorting-index-js": () => import("./../src/pages/blog/algorithms/java/sorting/index.js" /* webpackChunkName: "component---src-pages-blog-algorithms-java-sorting-index-js" */),
  "component---src-pages-blog-algorithms-java-sorting-iterative-bubble-sort-js": () => import("./../src/pages/blog/algorithms/java/sorting/iterative-bubble-sort.js" /* webpackChunkName: "component---src-pages-blog-algorithms-java-sorting-iterative-bubble-sort-js" */),
  "component---src-pages-blog-how-to-create-a-led-rave-mask-using-arduino-js": () => import("./../src/pages/blog/how-to-create-a-led-rave-mask-using-arduino.js" /* webpackChunkName: "component---src-pages-blog-how-to-create-a-led-rave-mask-using-arduino-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-my-favorite-github-student-pack-offers-and-why-they-are-important-js": () => import("./../src/pages/blog/my-favorite-github-student-pack-offers-and-why-they-are-important.js" /* webpackChunkName: "component---src-pages-blog-my-favorite-github-student-pack-offers-and-why-they-are-important-js" */),
  "component---src-pages-blog-pytesseract-images-to-html-js": () => import("./../src/pages/blog/pytesseract-images-to-html.js" /* webpackChunkName: "component---src-pages-blog-pytesseract-images-to-html-js" */),
  "component---src-pages-blog-social-media-app-api-versioning-js": () => import("./../src/pages/blog/social-media-app/api-versioning.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-api-versioning-js" */),
  "component---src-pages-blog-social-media-app-auth-setup-js": () => import("./../src/pages/blog/social-media-app/auth-setup.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-auth-setup-js" */),
  "component---src-pages-blog-social-media-app-creating-a-user-js": () => import("./../src/pages/blog/social-media-app/creating-a-user.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-creating-a-user-js" */),
  "component---src-pages-blog-social-media-app-creating-auth-endpoints-js": () => import("./../src/pages/blog/social-media-app/creating-auth-endpoints.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-creating-auth-endpoints-js" */),
  "component---src-pages-blog-social-media-app-index-js": () => import("./../src/pages/blog/social-media-app/index.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-index-js" */),
  "component---src-pages-blog-social-media-app-jwt-authentication-js": () => import("./../src/pages/blog/social-media-app/jwt-authentication.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-jwt-authentication-js" */),
  "component---src-pages-blog-social-media-app-jwt-endpoints-js": () => import("./../src/pages/blog/social-media-app/jwt-endpoints.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-jwt-endpoints-js" */),
  "component---src-pages-blog-social-media-app-postgresql-setup-js": () => import("./../src/pages/blog/social-media-app/postgresql-setup.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-postgresql-setup-js" */),
  "component---src-pages-blog-social-media-app-rails-setup-js": () => import("./../src/pages/blog/social-media-app/rails-setup.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-rails-setup-js" */),
  "component---src-pages-blog-social-media-app-serializers-js": () => import("./../src/pages/blog/social-media-app/serializers.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-serializers-js" */),
  "component---src-pages-blog-social-media-app-singleton-classes-js": () => import("./../src/pages/blog/social-media-app/singleton-classes.js" /* webpackChunkName: "component---src-pages-blog-social-media-app-singleton-classes-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-api-versioning-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-api-versioning.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-api-versioning-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-auth-setup-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-auth-setup.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-auth-setup-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-creating-a-user-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-creating-a-user.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-creating-a-user-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-creating-auth-endpoints-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-creating-auth-endpoints.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-creating-auth-endpoints-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-jwt-authentication-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-jwt-authentication.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-jwt-authentication-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-jwt-endpoints-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-jwt-endpoints.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-jwt-endpoints-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-postgresql-setup-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-postgresql-setup.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-postgresql-setup-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-rails-setup-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-rails-setup.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-rails-setup-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-serializers-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-serializers.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-serializers-js" */),
  "component---src-pages-blog-social-network-app-using-rails-and-react-native-singleton-classes-js": () => import("./../src/pages/blog/social-network-app-using-rails-and-react-native-singleton-classes.js" /* webpackChunkName: "component---src-pages-blog-social-network-app-using-rails-and-react-native-singleton-classes-js" */),
  "component---src-pages-experience-js": () => import("./../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-led-rave-mask-index-js": () => import("./../src/pages/projects/led-rave-mask/index.js" /* webpackChunkName: "component---src-pages-projects-led-rave-mask-index-js" */),
  "component---src-pages-projects-personal-portfolio-site-index-js": () => import("./../src/pages/projects/personal-portfolio-site/index.js" /* webpackChunkName: "component---src-pages-projects-personal-portfolio-site-index-js" */),
  "component---src-pages-projects-planspace-dev-js": () => import("./../src/pages/projects/planspace/dev.js" /* webpackChunkName: "component---src-pages-projects-planspace-dev-js" */),
  "component---src-pages-projects-planspace-index-js": () => import("./../src/pages/projects/planspace/index.js" /* webpackChunkName: "component---src-pages-projects-planspace-index-js" */),
  "component---src-pages-projects-plutus-dev-js": () => import("./../src/pages/projects/plutus/dev.js" /* webpackChunkName: "component---src-pages-projects-plutus-dev-js" */),
  "component---src-pages-projects-plutus-index-js": () => import("./../src/pages/projects/plutus/index.js" /* webpackChunkName: "component---src-pages-projects-plutus-index-js" */),
  "component---src-pages-projects-smart-dorm-lock-index-js": () => import("./../src/pages/projects/smart-dorm-lock/index.js" /* webpackChunkName: "component---src-pages-projects-smart-dorm-lock-index-js" */),
  "component---src-pages-projects-spacegrab-index-js": () => import("./../src/pages/projects/spacegrab/index.js" /* webpackChunkName: "component---src-pages-projects-spacegrab-index-js" */)
}

