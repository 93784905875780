module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Armaiz Adenwala","short_name":"Armaiz Adenwala","start_url":"/","background_color":"#283593","theme_color":"#283593","display":"minimal-ui","icon":"src/images/armaiz-adenwala-software-engineer-icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
